<script setup lang="ts">
import Uppy from '@uppy/core'
import XHR from '@uppy/xhr-upload'
import { toast } from 'vue3-toastify'
import FileInput from '@uppy/file-input'
import DropTarget from '@uppy/drop-target'

const {
  endpoint,
  disabled = false,
  allowedFileTypes,
  maxFileSize = 60 * 1024 * 1024,
  minNumberOfFiles = 1,
  maxNumberOfFiles = 1,
  help = `Please ensure that the file is legible and less than 20mb
Upload speed is determined by the internet connection being used`,
  dragOverText = 'Drag and drop files here',
} = defineProps<{
  endpoint: string
  disabled?: boolean
  allowedFileTypes?: string[]
  maxFileSize?: number
  minNumberOfFiles?: number
  maxNumberOfFiles?: number
  help?: string
  dragOverTitle?: string
  dragOverText?: string
}>()

// COUNTERS
const uploading = ref<number>(0)
const finished = ref<number>(0)

// COMPUTED
const isUploading = computed(() => uploading.value > 0)
const hasUploaded = computed(() => finished.value > 0)
const hasFiles = computed(() => hasUploaded.value || isUploading.value)

// UPLOADER
const uppy = new Uppy({
  autoProceed: true,
  restrictions: {
    maxFileSize,
    maxNumberOfFiles,
    minNumberOfFiles,
    allowedFileTypes,
  },
})
  .use(XHR, {
    endpoint,
    formData: true,
    bundle: false,
    fieldName: 'file',
    withCredentials: true,
  })
  .on('file-added', (file) => {
    uppy.setFileMeta(file.id, {
      name: file.name,
    })
  })
  .on('upload', (uploadID, files) => {
    uploading.value++
    // emit('upload', uploadID, files)
  })
  .on('upload-success', async (file, response) => {
    uploading.value--
    finished.value++
    // emit('uploadSuccess', file, response)
  })
  .on('error', (error) => {
    console.error(error)
  })
  .on('upload-error', (file, error, response) => {
    if (!file) return
    uploading.value--

    let message = `There was an error to upload ${file.name}`
    if (error.message) {
      message = `<strong class="font-semibold">${file!.name}</strong> \n${
        error.message
      }`
    }
    toast.error(message)
    // emit('uploadError', file, error, response)
  })
  .on('restriction-failed', (file, error) => {
    if (!file) return

    const oldFileWithError = uppy
      .getFiles()
      .filter((f) => file.name === f.name && f.error)

    const isCurrentDuplicated = error.message.includes(
      'Cannot add the duplicate file',
    )

    if (oldFileWithError.length > 0 && isCurrentDuplicated) {
      uppy.removeFile(oldFileWithError[0].id)
      setTimeout(() => {
        // @ts-expect-error - The UppyFile satisfies the MinimalRequiredUppyFile
        uppy.addFile(file)
      }, 200)
    } else {
      toast.error(
        `<strong class="font-semibold">${file!.name}</strong> \n${
          error.message || error.name
        }`,
      )
    }
  })

// INITIALIZE
const dropTarget = ref<HTMLElement>()
const fileSelector = ref<HTMLElement>()
onMounted(() => {
  uppy.use(DropTarget, {
    target: dropTarget.value!,
  })
  uppy.use(FileInput, {
    target: fileSelector.value!,
    locale: {
      strings: {
        chooseFiles: 'Browse',
      },
    },
  })
})
</script>

<template>
  <div
    ref="dropTarget"
    class="relative hidden w-full flex-col justify-between md:flex md:flex-row"
    :class="disabled && 'pointer-events-none opacity-50'"
    :data-drag-over-text="dragOverText"
  >
    <div
      class="flex w-full flex-col transition-all md:pb-0"
      :class="[hasFiles ? 'pb-4' : 'pb-0']"
    >
      <section
        class="relative flex w-full flex-col rounded-lg border-2 border-dashed border-gray-300 bg-gray-200 py-10 transition-all dark:border-gray-700 dark:bg-gray-800"
        :class="[hasFiles ? 'py-4' : 'py-10']"
      >
        <!-- Icon -->
        <span
          class="bg-primary/5 after:border-primary relative flex self-center rounded-full transition-all after:absolute after:inset-0 after:rounded-full after:border after:border-dashed after:content-['']"
          :class="[
            isUploading
              ? 'after:animate-spin-10-sec mb-2 p-2'
              : 'after:animate-spin-1-min mb-4 p-4',
          ]"
        >
          <Icon
            name="icon-upload-cloud"
            class="text-primary flex items-center justify-center"
            :class="{
              'h-7 w-7 text-2xl': !hasFiles,
              'h-5 w-5': hasFiles,
            }"
          />
        </span>

        <!-- Drag & Drop | Selector -->
        <span
          class="flex items-center justify-center gap-2 font-bold text-white"
          :class="{
            'text-lg': hasFiles,
            'text-xl': !hasFiles,
            'pointer-events-none': disabled,
          }"
        >
          Drag & Drop
          <span v-if="isUploading">more</span>
          Files, or
          <span ref="fileSelector" class="uppy-file-selector" />
        </span>

        <!-- Help -->
        <p
          v-if="help && !hasFiles"
          v-auto-animate
          class="mt-4 whitespace-break-spaces px-4 text-center text-sm text-gray-400"
        >
          {{ help }}
        </p>

        <!-- Progress bar -->
        <div
          v-if="isUploading"
          class="absolute bottom-px left-1 right-1 h-0.5 rounded-full"
        >
          <div
            class="animate-start-material-progress bg-primary relative m-0 h-full overflow-hidden"
          >
            <div class="bar animate-bar1"></div>
            <div class="bar animate-bar2"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.uppy-Dashboard-inner) {
  @apply !h-[200px] !w-full bg-neutral-200 md:!h-[500px] dark:bg-gray-700;
}

:deep(.uppy-Dashboard-AddFiles-title) {
  @apply text-black dark:text-white;
  button {
    @apply text-primary;
  }
}

:deep(.uppy-Dashboard-poweredBy) {
  @apply hidden;
}

.uppy-is-drag-over {
  &::after {
    @apply border-primary text-primary absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center whitespace-break-spaces rounded-lg border-2 border-dashed bg-gray-800/90 py-10 text-center text-2xl font-bold;
    content: attr(data-drag-over-text);
  }
}

:deep(.uppy-FileInput-btn) {
  @apply text-primary rounded-lg bg-transparent hover:underline;
}
</style>
